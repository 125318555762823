<template>
  <div class="expoleManageBox bgblue">
    <div class="top"></div>
    <div class="form">
      <el-form
        :model="formInline"
        class="demo-form-inline"
        style="position: relative"
        label-width="95px"
      >
        <!-- <el-row>
          <el-col :span="4"
            ><el-form-item label="勘探编号">
              <el-input
                v-model="queryInfo.condition.explorateCode"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="安装编号">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="客户姓名">
              <el-input
                v-model="queryInfo.condition.customerName"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4">
            <el-form-item label="手机">
              <el-input
                v-model="queryInfo.condition.mobile"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>

          <el-col :span="4">
            <el-form-item label="证件号">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <div v-show="isShow">
            <el-col :span="4"
              ><el-form-item label="金融单号">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4"
              ><el-form-item label="电站PSID">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="屋顶类型">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in roofType"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="4"
              ><el-form-item label="屋顶数量">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in roofNum"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4"
              ><el-form-item label="安装方式">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in installType"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4"
              ><el-form-item label="逆变器SN号">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in inverterSN"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="代理商名称">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="勘探状态">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in explorationStatus"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="安装状态">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in installationStatus"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="复查结果">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in results"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4"
              ><el-form-item label="审核人">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="安全驳回次数">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in securityRejections"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="合作类型">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in cooperationType"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="勘探地址异常">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in explorationAnomalies"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="勘探地区">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in explorationArea"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="所属分公司">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in firm"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="复查人">
                <el-input
                  v-model="formInline.user"
                  placeholder="请输入"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="勘探数据类型">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in dataType"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="安全单据类型">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in securityDocument"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="井网方式">
                <el-select v-model="formInline.region" placeholder="请选择">
                  <el-option
                    v-for="item in wellPattern"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
          </div>
          <el-col :span="8">
            <el-form-item label="按勘探审批时间">
              <el-date-picker
                style="width: 100%;"
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="text" @click="toShow"
              ><i
                :class="isShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              ></i
              >{{ isShow ? "收起" : "展开" }}</el-button
            >
            <el-button type="primary" @click="hook()">
              <i class="el-icon-search"></i>查询</el-button
            >
            <el-button @click="cz()">
              <i class="el-icon-refresh"></i>重置</el-button
            >
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="4"
            ><el-form-item label="客户姓名">
              <el-input
                v-model="queryInfo.condition.customerName"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>

          <el-col :span="4">
            <el-form-item label="订单编号">
              <el-input
                v-model="queryInfo.condition.customerNumber"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>

          <el-col :span="6" style="margin-left:20px; margin-top:5px">
            <!-- <el-button type="text"
              @click="toShow"
                ><i :class="isShow? 'el-icon-arrow-up': 'el-icon-arrow-down'"></i>{{isShow?'收起':'展开'}}</el-button
              > -->
            <el-button type="primary" @click="hook()">
              <i class="el-icon-search"></i>查询</el-button
            >
            <el-button @click="cz()">
              <i class="el-icon-refresh"></i>重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="bgwhite">
      <el-row>
        <el-button type="primary" plain>
          <i class="el-icon-upload2"></i>&nbsp;&nbsp;导出</el-button
        >
        <!-- <el-button type="primary" plain>
          <i class="el-icon-plus"></i>&nbsp;&nbsp;新增</el-button
        > -->
      </el-row>
      <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 320px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd'
        }"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column prop=" " label="操作" width="70" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="btn-orange"
              @click="sh(scope.row.customerCode,scope.row)"
              >审核</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="所属公司"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="customerName"
          label="客户名称"
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="customerNumber"
          label="客户短编码"
          width="140"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="explorateCode"
          label="勘探编号"
          width="250"
          align="center"
        >

        </el-table-column> -->
        <el-table-column
          prop="acheiveNum"
          label="安装编号"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span style="color: #409eff">{{ scope.row.exploeNum }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateDate"
          label="勘探提交时间"
          width="170"
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="acheiveNum"
          label="技术审核状态"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              :class="
                scope.row.techexamineStatus == 0
                  ? 'btn-blue'
                  : scope.row.techexamineStatus == 1
                  ? 'btn-green'
                  : 'btn-red'
              "
              >{{
                scope.row.techexamineStatus == 0
                  ? "未审核"
                  : scope.row.techexamineStatus == 1
                  ? "通过"
                  : "驳回"
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="acheiveNum"
          label="商务审核状态"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              :class="
                scope.row.businessexamineStatus == 0
                  ? 'btn-blue'
                  : scope.row.businessexamineStatus == 1
                  ? 'btn-green'
                  : 'btn-red'
              "
              >{{
                scope.row.businessexamineStatus == 0
                  ? "未审核"
                  : scope.row.businessexamineStatus == 1
                  ? "通过"
                  : "驳回"
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="acheiveNum"
          label="项目审核状态"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              :class="
                scope.row.programexamineStatus == 0
                  ? 'btn-blue'
                  : scope.row.programexamineStatus == 1
                  ? 'btn-green'
                  : scope.row.programexamineStatus == 2
                  ? 'btn-red'
                  : 'btn-orange'
              "
              >{{
                scope.row.programexamineStatus == 0
                  ? "未审核"
                  : scope.row.programexamineStatus == 1
                  ? "通过"
                  : scope.row.programexamineStatus == 2
                  ? "驳回"
                  : "审核中"
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="agentName"
          label="代理商名称"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="area" label="片区" width="100" align="center">
        </el-table-column>
        <el-table-column prop="mobile" label="手机" width="100" align="center">
        </el-table-column>
        <el-table-column
          prop="certificateNo"
          label="证件号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installionState"
          label="安装状态"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="cooperationType"
          label="合作类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="reviewResults"
          label="复查结果"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="recordName"
          label="复查人"
          width="150"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="installationRejection"
          label="安装驳回次数"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationRejection"
          label="勘探驳回次数"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="explorationData"
          label="勘探数据类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationDatatype"
          label="安装单据类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="colorSteel"
          label="是否封装彩刚"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationState"
          label="勘探状态"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="powerStation"
          label="电站类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationAnomalies"
          label="勘探异常情况"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="installationException"
          label="安装异常类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationSinglechip "
          label="勘探单片功率(W)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="componentSpecifications"
          label="组件规格"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationTypety"
          label="安装方式"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="roofTypess"
          label="屋顶类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="gridconnectionType"
          label="并网方式"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="numberPlanblocks"
          label="方案块数(块)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationPower"
          label="勘探功率"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationAddress"
          label="勘探地址"
          width="350"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="baseAddress"
          label="基准地址"
          width="350"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationRemarks"
          label="勘探备注"
          width="450"
          align="left"
        >
        </el-table-column>
        <el-table-column
          prop="explorationCreator"
          label="勘探创建人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationCreationtime"
          label="勘探创建时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationSubmitter"
          label="勘探提交人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationReviewtime"
          label="勘探提交时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installingSinglechippower"
          label="勘探审核人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="numberofInstallationblocks"
          label="勘探审核时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installionPower"
          label="安装单片功率(W)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="inverterColumnnumber"
          label="安装块数(块)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="numberofInverters"
          label="安装功率(W)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationNumer"
          label="逆变器列序号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="numberofInverters"
          label="逆变器台数"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationAddresss"
          label="安装地址"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationNotes"
          label="安装备注"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationCreator"
          label="安装创建人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationCreationtime"
          label="安装创建时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationSubmitter"
          label="安装提交人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationSubmissiontime"
          label="安装提交时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installedBy"
          label="安装更新人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationUpdatetime"
          label="安装更新时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationPreliminaryreviewer"
          label="安装初审人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationInitialreviewtime "
          label="安装初审时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationReviewperson"
          label="安装复审人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationReviewtime"
          label="安装复审时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="financialTrackingnumber"
          label="金融单号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="powerStationPSID"
          label="电站PSID"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationReviewsubmissiontime"
          label="勘探复查提交时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationReviewsubmissiontime"
          label="安装复查提交时间"
          width="150"
          align="center"
        >
        </el-table-column> -->
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count"
        >
        </el-pagination>
      </div>
    </div>
    <el-drawer title="客户详情" :visible.sync="drawer" size="70%">
      <div class="drw">
        <div class="drw-left">
          <div class="drw-user">
            <div class="jj">
              <div>客户编号：{{ usersdata.customerNumber }}</div>
              <div>姓名： {{ usersdata.customerName }}</div>
            </div>
            <div class="jj">
              <div>创建日期： {{ usersdata.createDate }}</div>
              <div>地址： {{ usersdata.address }}</div>
            </div>
          </div>
          <!-- <div class="drw-title">技术审核</div> -->
          <div class="box">
            <el-steps :active="active" finish-status="success" align-center>
              <el-step
                v-for="item in steps"
                :key="item.title"
                :title="item.title"
              ></el-step>
            </el-steps>
            <!-- 第一步 -->
            <!-- <div class="box-de" v-show="active == 0">
              <div class="de-one">
                <div class="de-de">
                  <div>勘探编号：{{ onedata.exploraterCode || "" }}</div>
                  <div>勘探时间： {{ onedata.createDate || "" }}</div>
                </div>
                <div class="de-de">
                  <div>勘探方式：{{ onedata.explorateType || "" }}</div>
                  <div>勘探人员： {{ onedata.exploraterName || "" }}</div>
                </div>
                <div class="de-de">
                  <div>客户编号： {{ onedata.customerCode || "" }}</div>
                  <div>业务员： {{ onedata.createName || "" }}</div>
                </div>
                <div class="de-de">
                  <div>安装方式： {{ onedata.explorateType || "" }}</div>
                  <div>客户类型： {{ onedata.salerName || "" }}</div>
                </div>
                <div class="de-de">
                  <div>电话：{{ onedata.exploraterPhone || "" }}</div>
                  <div>所在城市： {{ onedata.explorateArea || "" }}</div>
                </div>
                <div class="de-de">
                  <div>证件号码： {{ onedata.certificateNo || "" }}</div>
                  <div>年龄： {{ onedata.componentSpecification || "" }}</div>
                </div>
                <div class="de-de">
                  <div>地址： {{ onedata.explorateAddress || "" }}</div>
                </div>
              </div>
              <div class="de-img">
                <div class="img-tit">证照信息</div>
                <div class="imgde">
                  <div>证件信息</div>
                  <div class="imgli">
                    <img v-show="item.imgUrl" v-for="(item,i) in usermgdata.idImgList" :src="item.imgUrl" alt="" />
                  </div>
                </div>
                <div class="imgde">
                  <div>银行卡照片</div>
                  <div class="imgli">
                    <img v-show="item.imgUrl" v-for="(item,i) in usermgdata.bankImgList" :src="item.imgUrl" alt="" />
                  </div>
                </div>
                <div class="imgde">
                  <div>产权信息</div>
                  <div class="imgli">
                    <img v-show="item.imgUrl" v-for="(item,i) in usermgdata.propertyImgList" :src="item.imgUrl" alt="" />
                  </div>
                </div>
              </div>
              <div class="de-bom">
                <div class="bom-1">
                  <div style="margin-right: 10px;">审核结果</div>
                  <div>
                    <el-radio-group v-model="ondelist.processResult">
                      <el-radio :label="1" border>通过</el-radio>
                      <el-radio :label="0" border>不通过</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="bom-1">
                  <div style="margin-right: 10px;">审核意见:</div>
                  <el-button type="primary" @click="pushone()"><i class="el-icon-plus"></i>添加审核意见</el-button>
                </div>
                <div class="tips" v-for="(item,i) in ondelist.commentList" :key="i">
                  <div class="tips-tit">审核意见：</div>
                  <div class="xz" style="display: flex;">
                    <div class="xz_tit">选择照片：</div>
                    <el-button type="primary" @click="openimg(i,0)"
                      ><i class="el-icon-plus"></i>选择照片</el-button
                    >
                  </div>
                  <div class="imglis" style="margin-left: 30px;">
                    <img
                      v-for="(x,d) in ondelist.commentList[i].commentImgList"
                      style="width: 100px;height: 100px;margin-right: 20px;"
                      :src="x.commentImgUrl"
                      v-show="x.commentImgUrl"
                      alt=""
                    />
                  </div>
                  <div class="yj" style="display: flex;">
                    <div class="xz_tit">审核意见：</div>
                    <textarea
                      v-model="item.comment"
                      style="width:70%;height: 100px;"
                      name=""
                      id=""
                      placeholder="请输入审核意见"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- 第二部 -->

            <div class="box-de" v-show="active == 0">
              <div class="de-one">
                <div class="one-name">正大门照片</div>
                <div class="one-img" style="display: flex;width: 100%;">
                  <div v-for="(item, index) in twoimg.mainGateImgList">
                    <el-image
                      style="width: 100px; height: 100px;margin: 10px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
              <div class="de-one">
                <div class="one-name">主房房屋全景照片（可看清房屋层数）</div>
                <div class="one-img" style="display: flex;width: 100%;">
                  <div v-for="(item, index) in twoimg.fullViewImgList">
                    <el-image
                      style="width: 100px; height: 100px;margin: 10px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
              <div class="de-one">
                <div class="one-name">主房屋顶照片（可清晰看清房屋层数）</div>
                <div class="one-img" style="display: flex;width: 100%;">
                  <div v-for="(item, index) in twoimg.mainRoofImgList">
                    <el-image
                      style="width: 100px; height: 100px;margin: 10px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
              <div class="de-one">
                <div class="one-name">其他照片</div>
                <div class="one-img" style="display: flex;width: 100%;">
                  <div v-for="(item, index) in twoimg.azimuthImgList">
                    <el-image
                      style="width: 100px; height: 100px;margin: 10px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
              <div class="de-one">
                <div class="one-name">现勘表</div>
                <div class="one-img" style="display: flex;width: 100%;">
                  <div v-for="(item, index) in twoimg.surveyTableImgList">
                    <el-image
                      style="width: 100px; height: 100px;margin: 10px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
              <div class="de-one">
                <div class="one-name">现场视频</div>
                <div class="one-img" style="display: flex;width: 100%;">
                  <div>
              <!-- <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handelSceneVideo"
                :limit="2"
                :before-upload="beforeAvatarUploadVideo"
              >
                <div v-if="twoimg.sceneVideoList[0]?twoimg.sceneVideoList.length > 0:''" class="flex">
                  <div
                    style="margin: 5px"
                    class="imgBox"
                    v-for="(item, index) in twoimg.sceneVideoList"
                    :key="index"
                  >
                    <video :src="item.explorateImgUrl" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="handelVideo(item)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="handelRemoveSceneVideo(item)"
                      ></i>
                    </div>
                  </div>
                </div>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->
              <video
              style="width: 200px; height: 200px;"
                :src="item.explorateImgUrl"
                class="imgs"
                v-for="item in twoimg.sceneVideoList"
                :key="item.id"
                controls
              ></video>
            </div>
                  <!-- <div v-for="(item, index) in twoimg.sceneVideoList">
                    <el-image
                      style="width: 100px; height: 100px;margin: 10px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="twoimg.sceneVideoList"
                    >
                    </el-image>
                  </div> -->
                </div>
              </div>
              <div class="de-bom">
                <div class="bom-1">
                  <div style="margin-right: 10px;">审核结果:</div>
                  <div>
                    <el-radio-group v-model="twdelist.processResult">
                      <el-radio :label="1" border>通过</el-radio>
                      <el-radio :label="0" border>不通过</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="bom-1">
                  <div style="margin-right: 10px;">审核意见:</div>
                  <el-button
                    style="height: 32px;"
                    type="primary"
                    @click="pushtwo()"
                    ><i class="el-icon-plus"></i>添加审核意见</el-button
                  >
                </div>
                <div
                  class="tips"
                  v-for="(item, i) in twdelist.commentList"
                  :key="i"
                >
                  <div class="tips-tit">审核意见：</div>
                  <div class="xz" style="display: flex;">
                    <div class="xz_tit">选择照片：</div>
                    <el-button
                      style="height: 32px;"
                      type="primary"
                      @click="openimg(i, 1)"
                      ><i class="el-icon-plus"></i>选择照片</el-button
                    >
                  </div>
                  <div class="imglis" style="margin-left: 30px;">
                    <img
                      v-for="(x, d) in twdelist.commentList[i].commentImgList"
                      style="width: 100px;height: 100px;margin-right: 20px;"
                      :src="x.commentImgUrl"
                      v-show="x.commentImgUrl"
                      alt=""
                    />
                  </div>
                  <div class="yj" style="display: flex;">
                    <div class="xz_tit">审核意见：</div>
                    <textarea
                      v-model="item.comment"
                      style="width:70%;height: 100px;"
                      name=""
                      id=""
                      placeholder="请输入审核意见"
                    ></textarea>
                  </div>
                  <div class="colse" @click="close(1, i)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
              <div style="margin-left: 40%; ">
            <el-button
              type="primary"
              style="width: 90px;height: 30px;"
              @click="txt"
              v-if="active == 1"
              >上一步</el-button
            >
            <el-button
              type="primary"
              style="width: 90px;height: 30px;"
              @click="next"
              v-else
              >下一步</el-button
            >
            <el-button
              style="width: 90px;height: 30px;"
              type="primary"
              v-if="active == 1"
              @click="shok()"
              >审核完成</el-button
            >
              </div>
            </div>

            <!-- 第三步 -->

            <div class="box-3" v-show="active == 1">
              <div class="sj">设计图纸</div>
              <div class="sj-wj">
                <!-- <div class="sjname">设计图纸文件</div> -->
                <div class="wj">
                  <div
                    v-for="(item, i) in threedata.drawingList"
                    style="margin-right:30px ; text-align: center;"
                    v-show="item.drawingUrl"
                  >
                    <img
                      style="width: 60px;height: 60px;"
                      src="../../assets/images/PDF.png"
                      alt=""
                      @click="openpdf(item.drawingUrl)"
                    />
                    <div class="tii">{{ item.drawingName }}</div>
                  </div>
                </div>
              </div>
              <!-- <div class="sj">系统生成图</div> -->
              <div>
                <div class=" mp10">
        <div class="sj-tit">选择物料</div>
        <div class="sj-deta">
          <el-table :data="materialList" border>
            <el-table-column
              type="index"
              width="55"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column
              label="物料名称"
              prop="productName"
              align="center"
            />
            <el-table-column label="规格型号" prop="specs" align="center" />
            <el-table-column label="品牌" prop="brand" align="center" />
            <el-table-column label="数量" prop="conte" align="center">
            </el-table-column>
            <el-table-column label="单位" prop="unit" align="center" />
          </el-table>
        </div>
      </div>
              </div>
              <!-- <div class="sj-tit">自动生成方案</div>
              <div class="sj-deta">
                <div class="da-li">
                  <div>屋顶类型： {{ threedata.roofType }}</div>
                  <div>屋顶形状： {{ threedata.roofShape }}长方形</div>
                </div>
                <div class="da-li">
                  <div>长/a（米）： {{ threedata.roofLength }}</div>
                  <div>宽/b（米）： {{ threedata.roofWidth }}</div>
                </div>
                <div class="da-li">
                  <div>方案块数（块）： {{ threedata.schemeNum }}</div>
                  <div>方案总功率（w）： {{ threedata.schemePower }}</div>
                </div>
                <div class="da-li">
                  <div>安装方式：{{ threedata.installType }}</div>
                </div>
              </div>
              <div class="sj-tit">组件组排方案</div>
              <div class="sj-tu" style="text-align: center;margin-top: 30px">
                <div>屋顶1 组件排布图</div>
                <img
                  src="../../assets/images/null.png"
                  alt=""
                  style="width: 321px;height: 196px;"
                />
              </div> -->
              <!-- <div class="tip-box">
                <div>说明：</div>
                <div>1. 本屋顶可安装56块660W光伏组件</div>
                <div>2. 组件尺寸：2404 * 1323 * 35 (mm)</div>
                <div>
                  3.
                  本图仅供参考，如现场实际情况与图纸不符，请及时与技术人员联系，安装方式以技术人员最终确认为准。
                </div>
              </div> -->
              <div class="sj-tit">现场照片</div>
              <div class="xc-img">
                <div class="xclist" style="width: 139px;">
                  <div class="xc-tit">东</div>
                  <div
                    v-for="(item, index) in twoimg.sceneEastImgList"
                    style="width: 139px;height: 139px;"
                  >
                    <el-image
                      style="width: 139px;height: 139px;border-radius: 5px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                  <!-- <img
                    v-for="(item,index) in twoimg.sceneEastImgList"
                    style="width: 139px;height: 139px;border-radius: 5px;"
                    :src="item.explorateImgUrl"
                    alt=""
                  /> -->
                </div>
                <div class="xclist" style="width: 139px;">
                  <div class="xc-tit">南</div>
                  <div
                    v-for="(item, index) in twoimg.sceneSouthImgList"
                    style="width: 139px;height: 139px;"
                  >
                    <el-image
                      style="width: 139px;height: 139px;border-radius: 5px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                  <!-- <img
                    v-for="(item,index) in twoimg.sceneSouthImgList"
                    style="width: 139px;height: 139px;border-radius: 5px;"
                    :src="item.explorateImgUrl"
                    alt=""
                  /> -->
                </div>
                <div class="xclist" style="width: 139px;">
                  <div class="xc-tit">西</div>
                  <div
                    v-for="(item, index) in twoimg.sceneWestImgList"
                    style="width: 139px;height: 139px;"
                  >
                    <el-image
                      style="width: 139px;height: 139px;border-radius: 5px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                  <!-- <img
                    v-for="(item,index) in twoimg.sceneWestImgList"
                    style="width: 139px;height: 139px;border-radius: 5px;"
                    :src="item.explorateImgUrl"
                    alt=""
                  /> -->
                </div>
                <div class="xclist" style="width: 139px;">
                  <div class="xc-tit">北</div>
                  <div
                    v-for="(item, index) in twoimg.sceneNorthfacadeImgList"
                    style="width: 139px;height: 139px;"
                  >
                    <el-image
                      style="width: 139px;height: 139px;border-radius: 5px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                  <!-- <img
                    v-for="(item,index) in twoimg.sceneWestImgList"
                    style="width: 139px;height: 139px;border-radius: 5px;"
                    :src="item.explorateImgUrl"
                    alt=""
                  /> -->
                </div>
                <div class="xclist" style="width: 139px;">
                  <div class="xc-tit">东南</div>
                  <div
                    v-for="(item, index) in twoimg.sceneSoutheastImgList"
                    style="width: 139px;height: 139px;"
                  >
                    <el-image
                      style="width: 139px;height: 139px;border-radius: 5px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                  <!-- <img
                    v-for="(item,index) in twoimg.sceneSoutheastImgList"
                    style="width: 139px;height: 139px;border-radius: 5px;"
                    :src="item.explorateImgUrl"
                    alt=""
                  /> -->
                </div>


                <div class="xclist" style="width: 139px;">
                  <div class="xc-tit">西南</div>
                  <div
                    v-for="(item, index) in twoimg.sceneSouthwestImgList"
                    style="width: 139px;height: 139px;"
                  >
                    <el-image
                      style="width: 139px;height: 139px;border-radius: 5px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                  <!-- <img
                    v-for="(item,index) in twoimg.sceneWestImgList"
                    style="width: 139px;height: 139px;border-radius: 5px;"
                    :src="item.explorateImgUrl"
                    alt=""
                  /> -->
                </div>
                <div class="xclist" style="width: 139px;">
                  <div class="xc-tit">房屋内部照片</div>
                  <div
                    v-for="(item, index) in twoimg.sceneInternalImgList"
                    style="width: 139px;height: 139px;"
                  >
                    <el-image
                      style="width: 139px;height: 139px;border-radius: 5px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                </div>
                <div class="xclist" style="width: 139px;">
                  <div class="xc-tit">房屋结构内部照片</div>
                  <div
                    v-for="(item, index) in twoimg.sceneSouthfacadeImgList"
                    style="width: 139px;height: 139px;"
                  >
                    <el-image
                      style="width: 139px;height: 139px;border-radius: 5px;"
                      :src="item.explorateImgUrl"
                      :preview-src-list="[item.explorateImgUrl]"
                    >
                    </el-image>
                  </div>
                  <!-- <img
                    v-for="(item,index) in twoimg.sceneSouthfacadeImgList"
                    style="width: 139px;height: 139px;border-radius: 5px;"
                    :src="item.explorateImgUrl"
                    alt=""
                  /> -->
                </div>

              </div>
              <!-- <div class="sj-tit">现场视频</div>
              <div class="xc-video">
                <div class="xc-li">
                  <div>周围环境及屋面视频</div>
                  <video
                    style=" width: 200px; height: 200px;"
                      :src="item.explorateImgUrl"
                      class="imgs"
                      v-for="item in twoimg.scene_video"
                      :key="item.id"
                      controls
                    ></video>
                </div>
              </div> -->
              <div class="de-bom">
                <div class="bom-1">
                  <div style="margin-right: 10px;">审核结果</div>
                  <div>
                    <el-radio-group v-model="thrdelist.processResult">
                      <el-radio :label="1" border>通过</el-radio>
                      <el-radio :label="0" border>不通过</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="bom-1">
                  <div style="margin-right: 10px;">审核意见:</div>
                  <el-button
                    style="height: 32px;"
                    type="primary"
                    @click="pushthree()"
                    ><i class="el-icon-plus"></i>添加审核意见</el-button
                  >
                </div>
                <div
                  class="tips"
                  v-for="(item, i) in thrdelist.commentList"
                  :key="i"
                >
                  <div class="tips-tit">审核意见：</div>
                  <div class="xz" style="display: flex;">
                    <div class="xz_tit">选择照片：</div>
                    <el-button
                      style="height: 32px;"
                      type="primary"
                      @click="openimg(i, 2)"
                      ><i class="el-icon-plus"></i>选择照片</el-button
                    >
                  </div>
                  <div style="display: flex;">
                    <div class="imglis" style="margin-left: 30px;"  v-for="(x, d) in thrdelist.commentList[i].commentImgList">
                    <!-- <img
                      v-for="(x, d) in thrdelist.commentList[i].commentImgList"
                      style="width: 100px;height: 100px;margin-right: 20px;"
                      :src="x.commentImgUrl"
                      v-show="x.commentImgUrl"
                      alt=""
                    /> -->
                    <img
                      
                      style="width: 100px;height: 100px;margin-right: 20px;"
                      :src="x.commentImgUrl.toLowerCase().endsWith('.pdf')?'https://api.zhongliangxny.com/resources/upload/file_20240718105456962507800637612.png':x.commentImgUrl"
                      v-show="x.commentImgUrl"
                      @click="openpdf(x.commentImgUrl)"
                      alt=""
                    />
                    <div style="text-align: center;" v-if="x.name">{{ x.name }}</div>
                  </div>
                  </div>
                  <div class="yj" style="display: flex;">
                    <div class="xz_tit">审核意见：</div>
                    <textarea
                      v-model="item.comment"
                      style="width:70%;height: 100px;"
                      name=""
                      id=""
                      placeholder="请输入审核意见"
                    ></textarea>
                  </div>
                  <div class="colse" @click="close(2, i)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
              <div style="margin-left: 40%; ">
            <el-button
              type="primary"
              style="width: 90px;height: 30px;"
              @click="txt"
              v-if="active == 1"
              >上一步</el-button
            >
            <el-button
              type="primary"
              style="width: 90px;height: 30px;"
              @click="next"
              v-else
              >下一步</el-button
            >
            <el-button
              style="width: 90px;height: 30px;"
              type="primary"
              v-if="active == 1"
              @click="shok()"
              >审核完成</el-button
            >
              </div>
            </div>
            <!-- <div class="box-de" v-show="active == 1">
              <div class="de-img">
                <div class="imgde">
                  <div>身份证照片</div>
                  <div class="imgli">
                    <img v-show="item.imgUrl" v-for="(item,i) in fourdata.bwIdImgList" :src="item.imgUrl" alt="" />
                  </div>
                </div>
                <div class="imgde">
                  <div style="margin: 10px 0 ;">房屋归属证明</div>
                  <div class="imgli">
                    <img v-show="item.imgUrl" v-for="(item,i) in fourdata.bwPropertyImgList" :src="item.imgUrl" alt="" />
                  </div>
                </div>
                <div class="imgde">
                  <div style="margin: 10px 0 ;">并网申请表</div>
                  <div class="imgli">
                    <img v-show="item.imgUrl" v-for="(item,i) in fourdata.bwConnectionImgList" :src="item.imgUrl" alt="" />
                  </div>
                </div>
                <div class="imgde">
                  <div style="margin:  10px 0;">授权委托书</div>
                  <div class="imgli">
                    <img v-show="item.imgUrl" v-for="(item,i) in fourdata.bwPowerImgList" :src="item.imgUrl" alt="" />
                  </div>
                </div>
              </div>
              <div class="de-bom">
                <div class="bom-1">
                  <div style="margin-right: 10px;">审核结果</div>
                  <div>
                    <el-radio-group v-model="fourdelist.processResult">
                      <el-radio :label="1" border>通过</el-radio>
                      <el-radio :label="0" border>不通过</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="bom-1">
                  <div style="margin-right: 10px;">审核意见:</div>
                  <el-button type="primary" @click="pushfour()"><i class="el-icon-plus"></i>添加审核意见</el-button>
                </div>
                <div class="tips" v-for="(item,i) in fourdelist.commentList" :key="i">
                  <div class="tips-tit">审核意见：</div>
                  <div class="xz" style="display: flex;">
                    <div class="xz_tit">选择照片：</div>
                    <el-button type="primary" @click="openimg(i,3)"
                      ><i class="el-icon-plus"></i>选择照片</el-button
                    >
                  </div>
                  <div class="imglis" style="margin-left: 30px;">
                    <img
                      v-for="(x,d) in fourdelist.commentList[i].commentImgList"
                      style="width: 100px;height: 100px;margin-right: 20px;"
                      :src="x.commentImgUrl"
                      v-show="x.commentImgUrl"
                      alt=""
                    />
                  </div>
                  <div class="yj" style="display: flex;">
                    <div class="xz_tit">审核意见：</div>
                    <textarea
                      v-model="item.comment"
                      style="width:70%;height: 100px;"
                      name=""
                      id=""
                      placeholder="请输入审核意见"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div> -->
          </div>

        </div>
        <div class="drw-right">
          <!-- <el-button class="bo" type="primary" @click="shok()"
            >审核完成</el-button
          > -->
          <div class="right-tit" style="color: #333333;font-size: 20px;">
            历史记录
          </div>
          <div
            class="timex"
            style="margin-top: 40px;height: 62vh;overflow: auto;"
          >
            <!-- <el-timeline>
              <el-timeline-item
                v-for="(act, index) in rightdata"
                :key="index"
                color="#02a7f0"
                :size="large"
              >
                <div class="shjl">
                  <div style="color: #d6aa7f;font-size: 12px;margin-bottom: 8px;">{{ act.createDate }}</div>
                  <div>
                    基本信息审核结果:
                    <span style="color: #63a103;" :class="act.processList[0].processResult==1?'':'spnacitive'">【{{ act.processList[0].processResult==1?"通过":"不通过" }}】</span>
                  </div>
                  <div v-for="(x,c) in act.processList[0].commentList">
                    <div>{{ x. comment}}</div>
                    <img v-for="(d,f) in x.commentImgList" :src="d.commentImgCode" alt="">
                  </div>
                  <div>
                    房屋信息审核结果:
                    <span style="color: #63a103;" :class="act.processList[1].processResult==1?'':'spnacitive'">【{{ act.processList[1].processResult==1?"通过":"不通过" }}】</span>
                  </div>
                  <div v-for="(x,c) in act.processList[1].commentList">
                    <div>{{ x. comment}}</div>
                    <img v-show="x.commentImgList" v-for="(d,f) in x.commentImgList" :src="d.commentImgCode" alt="">
                  </div>
                  <div>
                    方案信息审核结果:
                    <span style="color: #63a103;" :class="act.processList[2].processResult==1?'':'spnacitive'">【{{ act.processList[2].processResult==1?"通过":"不通过" }}】</span>
                  </div>
                  <div v-for="(x,c) in act.processList[2].commentList">
                    <div>{{ x. comment}}</div>
                    <img v-for="(d,f) in x.commentImgList" :src="d.commentImgCode" alt="">
                  </div>
                  <div>
                    井网信息审核结果:
                    <span style="color: #63a103;" :class="act.processList[3].processResult==1?'':'spnacitive'">【{{ act.processList[3].processResult==1?"通过":"不通过" }}】</span>
                  </div>
                  <div v-for="(x,c) in act.processList[3].commentList">
                    <div>{{ x. comment}}</div>
                    <img v-for="(d,f) in x.commentImgList" :src="d.commentImgCode" alt="">
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline> -->
            <el-steps
              class="teps"
              reverse="false"
              finish-status="success"
              align-center
              direction="vertical"
              :rtl="true"
            >
              <el-step v-for="(act, index) in rightdata" :key="index">
                <template slot="description">
                  <div class="shjl">
                    <div
                      style="color: #d6aa7f;font-size: 12px;margin-bottom: 8px;"
                    >
                      {{ act.businesscreateDate }}
                    </div>
                    <div v-show="act.bussinessProcessList.length > 0">
                      商务审核人:
                      <span style="color: #409eff;">{{
                        act.businesscreateName
                      }}</span>
                    </div>
                    <div v-for="(e, h) in act.bussinessProcessList">
                      <div>
                        {{ e.processName }} 审核结果:
                        <span
                          style="color: #63a103;"
                          :class="e.processResult == 1 ? '' : 'spnacitive'"
                          >【{{
                            e.processResult == 1 ? "通过" : "不通过"
                          }}】</span
                        >
                      </div>
                      <div v-for="(x, c) in e.commentList">
                        <div v-if="x.comment">审核意见： {{ x.comment }}</div>
                        <img
                          style="width: 50px;height: 50px;"
                          v-for="(d, f) in x.commentImgList"
                          :src="d.commentImgUrl"
                          v-if="d.commentImgUrl"
                          alt=""
                        />
                      </div>
                    </div>
                    <!-- <div>
                      基本信息审核结果:
                      <span style="color: #63a103;" :class="act.processList[0].processResult==1?'':'spnacitive'">【{{ act.processList[0].processResult==1?"通过":"不通过" }}】</span>
                    </div>
                    <div v-for="(x,c) in act.processList[0].commentList">
                      <div v-if="x. comment">审核意见： {{ x. comment}}</div>
                      <img  style="width: 50px;height: 50px;" v-for="(d,f) in x.commentImgList" :src="d.commentImgUrl" v-if="d.commentImgUrl" alt="">
                    </div>
                    <div>
                      房屋信息审核结果:
                      <span style="color: #63a103;" :class="act.processList[1].processResult==1?'':'spnacitive'">【{{ act.processList[1].processResult==1?"通过":"不通过" }}】</span>
                    </div>
                    <div v-for="(x,c) in act.processList[0].commentList">
                      <div v-if="x. comment">审核意见：{{ x. comment}}</div>
                      <img  style="width: 50px;height: 50px;" v-show="x.commentImgList" v-for="(d,f) in x.commentImgList" :src="d.commentImgUrl" v-if="d.commentImgUrl" alt="">
                    </div>
                    <div>
                      方案信息审核结果:
                      <span style="color: #63a103;" :class="act.processList[2].processResult==1?'':'spnacitive'">【{{ act.processList[2].processResult==1?"通过":"不通过" }}】</span>
                    </div>
                    <div v-for="(x,c) in act.processList[1].commentList">
                      <div v-if="x. comment">审核意见：{{ x. comment}}</div>
                      <img style="width: 50px;height: 50px;" v-for="(d,f) in x.commentImgList" :src="d.commentImgUrl" v-if="d.commentImgUrl" alt="">
                    </div>
                    <div>
                      井网信息审核结果:
                      <span style="color: #63a103;" :class="act.processList[3].processResult==1?'':'spnacitive'">【{{ act.processList[3].processResult==1?"通过":"不通过" }}】</span>
                    </div>
                    <div v-for="(x,c) in act.processList[3].commentList">
                      <div v-if="x. comment">审核意见：{{ x. comment}}</div>
                      <img style="width: 50px;height: 50px;" v-for="(d,f) in x.commentImgList" :src="d.commentImgUrl" v-if="d.commentImgUrl" alt="">
                    </div> -->
                  </div>
                  <div class="shjl">
                    <div
                      style="color: #d6aa7f;font-size: 12px;margin-bottom: 8px;"
                    >
                      {{ act.techcreateDate }}
                    </div>
                    <div v-show="act.techProcessList.length > 0">
                      技术审核人:
                      <span style="color: #409eff;">{{
                        act.techcreateName
                      }}</span>
                    </div>
                    <div v-for="(e, h) in act.techProcessList">
                      <div>
                        {{ e.processName }} 审核结果:
                        <span
                          style="color: #63a103;"
                          :class="e.processResult == 1 ? '' : 'spnacitive'"
                          >【{{
                            e.processResult == 1 ? "通过" : "不通过"
                          }}】</span
                        >
                      </div>
                      <div v-for="(x, c) in e.commentList">
                        <div v-if="x.comment">审核意见： {{ x.comment }}</div>
                        <img
                          style="width: 50px;height: 50px;"
                          v-for="(d, f) in x.commentImgList"
                          :src="d.commentImgUrl"
                          v-if="d.commentImgUrl"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </el-step>
              <el-step>
                <template slot="description">
                  <div style="height: 50px;color: #333333;">流程开始</div>
                </template>
              </el-step>
            </el-steps>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 审核完成弹窗 -->
    <el-dialog title="审核结果" :visible.sync="dialogVisible" width="50%">
      <div class="jg">
        <div class="jg-o">
          <div class="jg1">审核结果：</div>
          <div class="jg2">{{ codeshow == 1 ? "通过" : "不通过" }}</div>
        </div>
        <div class="jg-li" style="margin-left: 100px;margin-top: 20px;">
          <div class="shjl" style="color:  #333333;font-size: 20px;">
            <!-- <div>
              <span style="font-weight: 700;">基本信息</span>审核结果:
              <span style="color: #63a103;" :class="ondelist.processResult==1?'':'spnacitive'">【{{ ondelist.processResult==1?"通过":"不通过" }}】</span>
            </div>
            <div class="shmag" v-for="(x,c) in ondelist.commentList">
              <div>审核意见：{{ x. comment}}</div>
              <img v-show="d.commentImgUrl" v-for="(d,f) in x.commentImgList" :src="d.commentImgUrl?d.commentImgUrl:'' " alt="">
            </div> -->
            <div>
              <span style="font-weight: 700;">房屋信息</span>审核结果:
              <span
                style="color: #63a103;"
                :class="twdelist.processResult == 1 ? '' : 'spnacitive'"
                >【{{ twdelist.processResult == 1 ? "通过" : "不通过" }}】</span
              >
            </div>
            <div class="shmag" v-for="(x, c) in twdelist.commentList">
              <div v-if="x.comment==''&&twdelist.processResult==1">审核意见：审核通过</div>
              <div v-else>审核意见：{{ x.comment }}</div>
              <img
                v-show="d.commentImgUrl"
                v-for="(d, f) in x.commentImgList"
                :src="d.commentImgUrl ? d.commentImgUrl : ''"
                alt=""
              />
            </div>
            <div></div>
            <div>
              <span style="font-weight: 700;">方案信息</span>审核结果:
              <span
                style="color: #63a103;"
                :class="thrdelist.processResult == 1 ? '' : 'spnacitive'"
                >【{{
                  thrdelist.processResult == 1 ? "通过" : "不通过"
                }}】</span
              >
            </div>
            <div class="shmag" v-for="(x, c) in thrdelist.commentList">
              <div v-if="x.comment==''&&thrdelist.processResult==1">审核意见：审核通过</div>
              <div v-else>审核意见：{{ x.comment }}</div>
              <div style="display: flex;">
                 <div v-for="(d, f) in x.commentImgList" >
                  <img
                    v-show="d.commentImgUrl"
                    
                    :src="d.commentImgUrl.toLowerCase().endsWith('.pdf')?'https://api.zhongliangxny.com/resources/upload/file_20240718105456962507800637612.png':d.commentImgUrl"
                    @click="openpdf(d.commentImgUrl)"
                    alt=""
                   />
                  <div style="text-align: center;" v-if="d.name">{{ d.name }}</div>
                 </div>
              </div>
            </div>
            <div></div>
            <!-- <div>
              <span style="font-weight: 700;">并网信息</span>审核结果:
              <span style="color: #63a103;" :class="fourdelist.processResult==1?'':'spnacitive'">【{{ fourdelist.processResult==1?"通过":"不通过" }}】</span>
            </div>
            <div class="shmag" v-for="(x,c) in fourdelist.commentList">
              <div>审核意见：{{ x. comment}}</div>
              <img v-show="d.commentImgUrl" v-for="(d,f) in x.commentImgList" :src="d.commentImgUrl?d.commentImgUrl:''" alt="">
            </div> -->
            <div></div>
          </div>
        </div>
      </div>

      <div style="text-align: center;margin-top: 30px">
        <el-button
          style="width: 105px;height: 43px;"
          @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          style="width: 105px;height: 43px;"
          type="primary"
          @click="submit()"
          >提交结果</el-button
        >
      </div>
    </el-dialog>
    <!-- 选择图片弹窗 -->
    <el-dialog title="选择图片" :visible.sync="dialogVisibleimg" width="50%">
      <div class="jgdrg" v-show="diashow == 0">
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">证件信息*</div>
          <div class="list">
            <img
              v-for="(item, i) in usermgdata.idImgList"
              :key="i"
              :src="item.imgUrl"
              alt=""
              :class="item.flag == 1 ? 'listactive' : ''"
              @click="xzone(i)"
            />
          </div>
        </div>
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">银行卡照片*</div>
          <div class="list">
            <img
              v-for="(item, i) in usermgdata.bankImgList"
              :key="i"
              :src="item.imgUrl"
              alt=""
              :class="item.flag == 1 ? 'listactive' : ''"
              @click="xzone1(i)"
            />
          </div>
        </div>
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">产权信息*</div>
          <div class="list">
            <img
              v-for="(item, i) in usermgdata.propertyImgList"
              :key="i"
              :src="item.url"
              alt=""
              :class="item.flag == 1 ? 'listactive' : ''"
              @click="xzone2(i)"
            />
          </div>
        </div>
      </div>
      <div class="jgdrg" v-show="diashow == 1">
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">正大门照片*</div>
          <div class="list">
            <img
              :class="item.flag === 1 ? 'listactive' : ''"
              v-for="(item, i) in twoimg.mainGateImgList"
              :key="i"
              :src="item.explorateImgUrl"
              alt=""
              @click="xztwo(i)"
            />
          </div>
        </div>
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">
            主房房屋全景照片*
          </div>
          <div class="list">
            <img
              v-for="(item, i) in twoimg.fullViewImgList"
              :key="i"
              :src="item.explorateImgUrl"
              alt=""
              :class="[item.flag == 1 ? 'listactive' : '']"
              @click="xzthree(i)"
            />
          </div>
        </div>
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">主房屋顶照片*</div>
          <div class="list">
            <img
              v-for="(item, i) in twoimg.mainRoofImgList"
              :key="i"
              :src="item.explorateImgUrl"
              alt=""
              :class="[item.flag == 1 ? 'listactive' : '']"
              @click="xzfour(i)"
            />
          </div>
        </div>
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">其他照片*</div>
          <div class="list">
            <img
              v-for="(item, i) in twoimg.azimuthImgList"
              :key="i"
              :src="item.explorateImgUrl"
              alt=""
              :class="[item.flag == 1 ? 'listactive' : '']"
              @click="xzfive(i)"
            />
          </div>
        </div>
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">现勘表*</div>
          <div class="list">
            <img
              v-for="(item, i) in twoimg.surveyTableImgList"
              :key="i"
              :src="item.explorateImgUrl"
              alt=""
              :class="[item.flag == 1 ? 'listactive' : '']"
              @click="xzsix(i)"
            />
          </div>
        </div>

      </div>
      <div class="jgdrg" v-show="diashow == 2">
        <div class="imglist">
          <div class="xc-img" style="display: flex;flex-wrap: wrap;">
            <div
              class="xclist"
              style="width: 139px;margin-bottom:10px;margin-right: 25px;"
            >
              <div class="xc-tit">东</div>
              <img
                :class="item.flag === 1 ? 'listactive' : ''"
                v-for="(item, index) in twoimg.sceneEastImgList"
                style="width: 139px;height: 139px;border-radius: 5px;"
                :src="item.explorateImgUrl"
                alt=""
                @click="xzd(index, 0)"
              />
            </div>
            <div
              class="xclist"
              style="width: 139px;margin-bottom:10px;margin-right: 25px;"
            >
              <div class="xc-tit">西</div>
              <img
                :class="item.flag === 1 ? 'listactive' : ''"
                v-for="(item, index) in twoimg.sceneSouthImgList"
                style="width: 139px;height: 139px;border-radius: 5px;"
                :src="item.explorateImgUrl"
                alt=""
                @click="xzd(index, 2)"
              />
            </div>
            <div
              class="xclist"
              style="width: 139px;margin-bottom:10px;margin-right: 25px;"
            >
              <div class="xc-tit">南</div>
              <img
                :class="item.flag === 1 ? 'listactive' : ''"
                v-for="(item, index) in twoimg.sceneWestImgList"
                style="width: 139px;height: 139px;border-radius: 5px;"
                :src="item.explorateImgUrl"
                alt=""
                @click="xzd(index, 3)"
              />
            </div>
            <div
              class="xclist"
              style="width: 139px;margin-bottom:10px;margin-right: 25px;"
            >
              <div class="xc-tit">北</div>
              <img
                :class="item.flag === 1 ? 'listactive' : ''"
                v-for="(item, index) in twoimg.sceneNorthfacadeImgList"
                style="width: 139px;height: 139px;border-radius: 5px;"
                :src="item.explorateImgUrl"
                alt=""
                @click="xzd(index, 4)"
              />
            </div>
            <div
              class="xclist"
              style="width: 139px;margin-bottom:10px;margin-right: 25px;"
            >
              <div class="xc-tit">东南</div>
              <img
                :class="item.flag === 1 ? 'listactive' : ''"
                v-for="(item, index) in twoimg.sceneSoutheastImgList"
                style="width: 139px;height: 139px;border-radius: 5px;"
                :src="item.explorateImgUrl"
                alt=""
                @click="xzd(index, 1)"
              />
            </div>
            <div
              class="xclist"
              style="width: 139px;margin-bottom:10px;margin-right: 25px;"
            >
              <div class="xc-tit">西南</div>
              <img
                :class="item.flag === 1 ? 'listactive' : ''"
                v-for="(item, index) in twoimg.sceneSouthwestImgList"
                style="width: 139px;height: 139px;border-radius: 5px;"
                :src="item.explorateImgUrl"
                alt=""
                @click="xzd(index, 'xn')"
              />
            </div>

            <div
              class="xclist"
              style="width: 139px;margin-bottom:10px;margin-right: 25px;"
            >
              <div class="xc-tit">房屋内部照片</div>
              <img
                :class="item.flag === 1 ? 'listactive' : ''"
                v-for="(item, index) in twoimg.sceneInternalImgList"
                style="width: 139px;height: 139px;border-radius: 5px;"
                :src="item.explorateImgUrl"
                alt=""
                @click="xzd(index, 6)"
              />
            </div>

            <div
              class="xclist"
              style="width: 139px;margin-bottom:10px;margin-right: 25px;"
            >
              <div class="xc-tit">房屋结构内部照片</div>
              <img
                :class="item.flag === 1 ? 'listactive' : ''"
                v-for="(item, index) in twoimg.sceneSouthfacadeImgList"
                style="width: 139px;height: 139px;border-radius: 5px;"
                :src="item.explorateImgUrl"
                alt=""
                @click="xzd(index, 5)"
              />
            </div>

          </div>
          <div class="sj-wj">
                <div class="sjname">设计图纸文件</div>
                <div class="wj" style="display: flex; ">
                  <div
                    v-for="(item, i) in threedata.drawingList"
                    style="margin-right:30px ; text-algin:center"
                    v-show="item.drawingUrl"
                    :class="item.flag === 1 ? 'listactive' : ''"
                    @click="xzd(i,'pdf')"
                  >
                    <img
                      style="width: 80px;height: 80px;"
                      src="../../assets/images/PDF.png"
                      alt=""

                    />
                    <div class="tii" style="text-align: center;">{{ item.drawingName }}</div>
                  </div>
                </div>
        </div>
        </div>
      </div>
      <div class="jgdrg" v-show="diashow == 3">
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">身份证照片*</div>
          <div class="list">
            <img
              v-for="(item, i) in fourdata.bwIdImgList"
              :key="i"
              :src="item.imgUrl"
              alt=""
              :class="item.flag == 1 ? 'listactive' : ''"
              @click="bwxz1(i)"
            />
          </div>
        </div>
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">房屋归属证明*</div>
          <div class="list">
            <img
              v-for="(item, i) in fourdata.bwPropertyImgList"
              :key="i"
              :src="item.imgUrl"
              alt=""
              :class="item.flag == 1 ? 'listactive' : ''"
              @click="bwxz2(i)"
            />
          </div>
        </div>
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">并网申请表*</div>
          <div class="list">
            <img
              v-for="(item, i) in fourdata.bwConnectionImgList"
              :key="i"
              :src="item.url"
              alt=""
              :class="item.flag == 1 ? 'listactive' : ''"
              @click="bwxz3(i)"
            />
          </div>
        </div>
        <div class="imglist">
          <div style="font-size: 20px;margin-bottom: 20px">授权委托书*</div>
          <div class="list">
            <img
              v-for="(item, i) in fourdata.bwPowerImgList"
              :key="i"
              :src="item.url"
              alt=""
              :class="item.flag == 1 ? 'listactive' : ''"
              @click="bwxz4(i)"
            />
          </div>
        </div>
      </div>
      <div style="text-align: center;margin-top: 30px">
        <el-button
          style="width: 105px;height: 43px;"
          @click="dialogVisibleimg = false"
          >取 消</el-button
        >
        <el-button
          style="width: 105px;height: 43px;"
          type="primary"
          @click="pushimg"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  methods: {
    getData(row) {
      this.$http
        .post("/houseExplorateBase/detail", {
          explorateCode: row.explorateCode
        })
        .then(res => {
          if (res.data.code === 200) {
            // this.obj = res.data.data;
            // this.obj.materialList.forEach(item => {
            //   item.product_name = item.productName;
            // });
            this.materialList = res.data.data.materialList;
            // 大门
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
              duration: 2000
            });
          }
        });
    },
    handelVideo(item) {
      this.dialogVisibleVideo = true;
      this.dialogVideoUrl = item.explorateImgUrl;
    },
    handelRemoveSceneVideo(item) {
      remove(item.explorateImgUrl, this.sceneVideo);
    },
    // 视频
    handelSceneVideo(response) {
      this.sceneVideo = add("scene_video", response.data.url, this.sceneVideo);
    },
    handelRemoveSceneVideo(item) {
      remove(item.explorateImgUrl, this.sceneVideo);
    },
    beforeAvatarUploadVideo(file) {
      console.log(file.type, "video");
      const VideoType =
        file.type === "video/mp4" ||
        file.type === "video/avi" ||
        file.type === "video/wmv" ||
        file.type === "video/rmvb";

      if (!VideoType) {
        this.$message.error("上传视频只能是 MP4 、AVI 、WMV 、RMVB 格式!");
      }
      return VideoType;
    },
    close(x, i) {
      if (x == 1) {
        this.twdelist.commentList.splice(i, 1);
      } else {
        this.thrdelist.commentList.splice(i, 1);
      }
    },
    async down(urls) {
      const pdfUrl = urls;
      const response = await axios({
        url: pdfUrl,
        method: "GET",
        responseType: "blob"
      });
      console.log(response);
      // const url = window.URL.createObjectURL(new Blob([response.data]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'document.pdf');
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    },
    openpdf(url) {
      if(url.toLowerCase().endsWith('.pdf')){
        window.open(url);
      } else {
        return
      }

    },
    submit() {
      if (this.isClicked) return;

      this.isClicked = true; // 设置标志位，表示按钮已经被点击

      this.bzde.processList = [];
      // this.bzde.processList.push(this.ondelist)
      this.bzde.processList.push(this.twdelist);
      this.bzde.processList.push(this.thrdelist);
      // this.bzde.processList.push(this.fourdelist)

      const userinfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userinfo, "iii");
      this.bzde.customerCode = this.coid;
      this.bzde.customerName = this.usersdata.customerName;
      this.bzde.explorateCode = this.usersdata.explorateCode;
      this.bzde.techcreateCode = userinfo.userInfo.userCode;
      this.bzde.techcreateName = userinfo.userInfo.name;
      this.bzde.techStatus = this.codeshow;
      // this.bzde.nodeStatus = this.codeshow
      console.log(this.bzde);
      this.$http
        .post("houseExplorateBase/techlogyAudit", this.bzde)
        .then(res => {
          // if(res.data.code ==200){
          //   this.$http.post("houseCustomer/updateStatus", {
          //     dataStatus:this.codeshow==1?'5':'4',
          //     customerCode:this.coid,
          //     customerName:this.usersdata.customerName
          //   }).then(res=>{

          //   })
          this.getdata();
          this.dialogVisible = false;
          this.drawer = false;
          if (res.data.message != "SUCCESS") {
            this.$message({
              message: res.data.message,
              type: "warning"
            });
          } else {
            this.$message({
              message: '审核完成',
              type: "success"
            });
          }
          // }
          setTimeout(() => {
            this.isClicked = false;
          }, 3000);
        });
    },
    shok() {
      this.dialogVisible = true;
      console.log(
        this.ondelist,
        this.twdelist,
        this.thrdelist,
        this.fourdelist,
        11
      );
      if (
        this.twdelist.processResult == 1 &&
        this.thrdelist.processResult == 1
      ) {
        this.codeshow = 1;
      } else {
        this.codeshow = 0;
      }
    },
    // 第一步添加审核意见
    pushone() {
      this.ondelist.commentList.push({
        comment: "",
        commentNo: "",
        commentImgList: [
          {
            commentImgNo: "",
            commentImgUrl: ""
          }
        ]
      });
    },
    // 第二步添加审核意见
    pushtwo() {
      this.twdelist.commentList.push({
        comment: "",
        commentNo: "",
        commentImgList: [
          {
            commentImgNo: "",
            commentImgUrl: ""
          }
        ]
      });
    },
    // 第三步添加审核意见
    pushthree() {
      this.thrdelist.commentList.push({
        comment: "",
        commentNo: "",
        commentImgList: [
          {
            commentImgNo: "",
            commentImgUrl: ""
          }
        ]
      });
    },
    pushfour() {
      this.fourdelist.commentList.push({
        comment: "",
        commentNo: "",
        commentImgList: [
          {
            commentImgNo: "",
            commentImgUrl: ""
          }
        ]
      });
    },
    toShow() {
      this.isShow = !this.isShow;
    },
    pushimg() {
      this.dialogVisibleimg = false;
      if (this.diashow == 0) {
        const flagged = this.usermgdata.idImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.imgUrl
            };
          });
        const flaggeds = this.usermgdata.bankImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.imgUrl
            };
          });
        const flaggedss = this.usermgdata.propertyImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.imgUrl
            };
          });
        console.log(this.usermgdata, flagged, "sss");
        this.imglll = [...flaggedss, ...flaggeds, ...flagged];
        console.log(this.imglll, "lll");
        this.ondelist.commentList[this.d].commentImgList = this.imglll;
        console.log(this.ondelist, "000");
      }
      if (this.diashow == 1) {
        const flaggedUrls = this.twoimg.mainGateImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrlss = this.twoimg.fullViewImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrlsss = this.twoimg.mainRoofImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrlssss = this.twoimg.azimuthImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
          const flaggedUrlsssss = this.twoimg.surveyTableImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });

        this.imgll = [
          ...flaggedUrlsssss,
          ...flaggedUrlssss,
          ...flaggedUrlsss,
          ...flaggedUrlss,
          ...flaggedUrls
        ];
        this.twdelist.commentList[this.d].commentImgList = this.imgll;
      }
      if (this.diashow == 2) {
        //方案信息图片
        const flaggedUrlone = this.twoimg.sceneEastImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrltwo = this.twoimg.sceneSoutheastImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrlthree = this.twoimg.sceneSouthImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrlfour = this.twoimg.sceneNorthfacadeImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrlfive = this.twoimg.sceneWestImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrlsix = this.twoimg.sceneSouthfacadeImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrlseven = this.twoimg.sceneInternalImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
          });
        const flaggedUrlsxn = this.twoimg.sceneSouthwestImgList
        .filter(item => item.flag === 1)
        .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.explorateImgUrl
            };
        });
        const flaggedUrlpdf = this.threedata.drawingList
        .filter(item => item.flag === 1)
        .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.drawingUrl,
              name:item.drawingName
            };
        });
        this.imglll = [
          ...flaggedUrlone,
          ...flaggedUrltwo,
          ...flaggedUrlthree,
          ...flaggedUrlfour,
          ...flaggedUrlfive,
          ...flaggedUrlsix,
          ...flaggedUrlseven,
          ...flaggedUrlsxn,
          ...flaggedUrlpdf
        ];
        console.log(this.imglll, "lll");
        this.thrdelist.commentList[this.d].commentImgList = this.imglll;
        console.log(this.thrdelist);
      }
      if (this.diashow == 3) {
        const flagged = this.fourdata.bwIdImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.imgUrl
            };
          });
        const flaggeds = this.fourdata.bwPropertyImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.imgUrl
            };
          });
        const flaggedss = this.fourdata.bwConnectionImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.imgUrl
            };
          });
        const flaggedsss = this.fourdata.bwPowerImgList
          .filter(item => item.flag === 1)
          .map((item, i) => {
            return {
              commentImgNo: i,
              commentImgUrl: item.imgUrl
            };
          });
        this.imglll = [...flaggedss, ...flaggeds, ...flagged, ...flaggedsss];
        this.fourdelist.commentList[this.d].commentImgList = this.imglll;
      }
    },
    bwxz1(i) {
      if (this.fourdata.bwIdImgList[i].flag == 1) {
        this.fourdata.bwIdImgList[i].flag = 0;
      } else {
        this.fourdata.bwIdImgList[i].flag = 1;
      }
    },
    bwxz2(i) {
      if (this.fourdata.bwPropertyImgList[i].flag == 1) {
        this.fourdata.bwPropertyImgList[i].flag = 0;
      } else {
        this.fourdata.bwPropertyImgList[i].flag = 1;
      }
    },
    bwxz3(i) {
      if (this.fourdata.bwConnectionImgList[i].flag == 1) {
        this.fourdata.bwConnectionImgList[i].flag = 0;
      } else {
        this.fourdata.bwConnectionImgList[i].flag = 1;
      }
    },
    bwxz4(i) {
      if (this.fourdata.bwPowerImgList[i].flag == 1) {
        this.fourdata.bwPowerImgList[i].flag = 0;
      } else {
        this.fourdata.bwPowerImgList[i].flag = 1;
      }
    },
    xzone(i) {
      if (this.usermgdata.idImgList[i].flag == 1) {
        this.usermgdata.idImgList[i].flag = 0;
      } else {
        this.usermgdata.idImgList[i].flag = 1;
      }
    },
    xzone1(i) {
      if (this.usermgdata.bankImgList[i].flag == 1) {
        this.usermgdata.bankImgList[i].flag = 0;
      } else {
        this.usermgdata.bankImgList[i].flag = 1;
      }
    },
    xzone2(i) {
      if (this.usermgdata.propertyImgList[i].flag == 1) {
        this.usermgdata.propertyImgList[i].flag = 0;
      } else {
        this.usermgdata.propertyImgList[i].flag = 1;
      }
    },
    xztwo(i) {
      if (this.twoimg.mainGateImgList[i].flag == 1) {
        this.twoimg.mainGateImgList[i].flag = 0;
      } else {
        this.twoimg.mainGateImgList[i].flag = 1;
      }
      // this.twoimg.mainGateImgList[i].flag = 1;
      // console.log(this.twoimg.mainGateImgList);
      // this.dialogVisibleimg =true
      // this.$set( this.twoimg.mainGateImgList)
    },
    xzthree(i) {
      if (this.twoimg.fullViewImgList[i].flag == 1) {
        this.twoimg.fullViewImgList[i].flag = 0;
      } else {
        this.twoimg.fullViewImgList[i].flag = 1;
      }
    },
    xzfour(i) {
      if (this.twoimg.mainRoofImgList[i].flag == 1) {
        this.twoimg.mainRoofImgList[i].flag = 0;
      } else {
        this.twoimg.mainRoofImgList[i].flag = 1;
      }
    },
    xzfive(i) {
      if (this.twoimg.azimuthImgList[i].flag == 1) {
        this.twoimg.azimuthImgList[i].flag = 0;
      } else {
        this.twoimg.azimuthImgList[i].flag = 1;
      }
    },
    xzsix(i) {
      if (this.twoimg.surveyTableImgList[i].flag == 1) {
        this.twoimg.surveyTableImgList[i].flag = 0;
      } else {
        this.twoimg.surveyTableImgList[i].flag = 1;
      }
    },
    xzd(index, s) {
      if (s == 0) {
        if (this.twoimg.sceneEastImgList[index].flag == 1) {
          this.twoimg.sceneEastImgList[index].flag = 0;
        } else {
          this.twoimg.sceneEastImgList[index].flag = 1;
        }
      }
      if (s == 1) {
        if (this.twoimg.sceneSoutheastImgList[index].flag == 1) {
          this.twoimg.sceneSoutheastImgList[index].flag = 0;
        } else {
          this.twoimg.sceneSoutheastImgList[index].flag = 1;
        }
      }
      if (s == 2) {
        if (this.twoimg.sceneSouthImgList[index].flag == 1) {
          this.twoimg.sceneSouthImgList[index].flag = 0;
        } else {
          this.twoimg.sceneSouthImgList[index].flag = 1;
        }
      }
      if (s == 3) {
        if (this.twoimg.sceneWestImgList[index].flag == 1) {
          this.twoimg.sceneWestImgList[index].flag = 0;
        } else {
          this.twoimg.sceneWestImgList[index].flag = 1;
        }
      }
      if (s == 4) {
        if (this.twoimg.sceneNorthfacadeImgList[index].flag == 1) {
          this.twoimg.sceneNorthfacadeImgList[index].flag = 0;
        } else {
          this.twoimg.sceneNorthfacadeImgList[index].flag = 1;
        }
      }
      if (s == 5) {
        if (this.twoimg.sceneSouthfacadeImgList[index].flag == 1) {
          this.twoimg.sceneSouthfacadeImgList[index].flag = 0;
        } else {
          this.twoimg.sceneSouthfacadeImgList[index].flag = 1;
        }
      }
      if (s == 6) {
        if (this.twoimg.sceneInternalImgList[index].flag == 1) {
          this.twoimg.sceneInternalImgList[index].flag = 0;
        } else {
          this.twoimg.sceneInternalImgList[index].flag = 1;
        }
      }
      if(s=='pdf'){
        if (this.threedata.drawingList[index].flag== 1) {
          this.threedata.drawingList[index].flag = 0;
        } else {
          this.threedata.drawingList[index].flag = 1;
        }
      }
      if (s == 'xn') {
        if (this.twoimg.sceneSouthwestImgList[index].flag == 1) {
          this.twoimg.sceneSouthwestImgList[index].flag = 0;
        } else {
          this.twoimg.sceneSouthwestImgList[index].flag = 1;
        }
      }

    },
    handleClick(row) {
      console.log(row);
    },
    onSubmit() {
      console.log("submit!");
    },
    sh(id,row) {
      console.log(id, "id");
      this.coid = id;
      this.drawer = true;
      this.$http
        .post("houseCustomer/queryExplorateDetail", {
          customerCode: id
        })
        .then(res => {
          this.usersdata = res.data.data;
          if (res.data.data.houseExplorateBase) {
            this.onedata = res.data.data.houseExplorateBase;
            this.usermgdata = res.data.data.houseExplorateBase;
          }
          if (res.data.data.houseExplorateHouse) {
            this.twoimg = res.data.data.houseExplorateHouse;
          }
          if (res.data.data.houseExplorateScheme) {
            this.threedata = res.data.data.houseExplorateScheme;
          }
          if (res.data.data.houseExplorateBw) {
            this.fourdata = res.data.data.houseExplorateBw;
          }
          this.threedata.drawingList = res.data.data.drawingList;
          console.log(this.threedata, "this.fourdata");
          this.$http
            .post("houseExplorateExamineNode/queryNodeDetail", {
              explorateCode: res.data.data.explorateCode
            })
            .then(res => {
              console.log(res.data.data, "rightdata");
              this.rightdata = res.data.data;
              // 使步骤条倒序
              this.$nextTick(() => {
                var x = document.querySelectorAll(".teps .el-step__icon-inner");
                console.log(x, "pppppp");
                x.forEach((item, index) => {
                  item.innerHTML = x.length - index;
                });
              });
            });
        });
        this.getData(row)
      // this.$http
      //   .post("houseExplorateBase/queryOne",{
      //   customerCode:id
      // })
      //   .then(res => {
      //     this.onedata = res.data.data;
      //   });
      //   this.$http
      //   .post("houseCustomer/queryOne",{
      //   customerCode:id
      // })
      //   .then(res => {
      //     this.usersdata = res.data.data;
      //   });
      // this.$http
      //   .post("houseExplorateHouse/queryOne", this.queryInfo)
      //   .then(res => {
      //     this.twodata = res.data.data;
      //     this.twoimg = res.data.data;
      //   });
      // this.$http
      //   .post("houseExplorateScheme/queryOne", this.queryInfo)
      //   .then(res => {
      //     this.threedata = res.data.data;
      //   });
      // this.$http.post("houseExplorateBw/queryOne", this.queryInfo).then(res => {
      //   this.fourdata = res.data.data;
      // });
      // this.$http.post("houseExplorateExamineNode/queryNodeDetail", {
      //   customerCode:id
      // }).then(res => {
      //   console.log(res.data.data);
      //   this.rightdata = res.data.data;
      //   // 使步骤条倒序
      //   this.$nextTick(()=>{
      //     var x = document.querySelectorAll(".teps .el-step__icon-inner")
      //     console.log(x,'pppppp');
      //     x.forEach((item,index)=>{
      //       item.innerHTML = x.length-index
      //     })
      //   })
      // });
      // 基本信息照片
      // this.$http.post("houseCustomer/queryImgList", {
      //   customerCode:id
      // }).then(res => {
      //   this.usermgdata = res.data.data;
      // });
    },
    next() {
      if (this.active++ > 2) this.active = 0;
      console.log(this.twdelist, "ffff");
    },
    txt() {
      this.active--;
    },
    getdata() {
      this.$http
        .post("houseExplorateBase/techlogyAuditList", this.queryInfo)
        .then(res => {
          this.tableData = res.data.data.data;
          this.count = res.data.data.count;
        });
    },
    openimg(d, i) {
      this.$http
        .post("houseCustomer/queryExplorateDetail", {
          customerCode: this.coid
        })
        .then(res => {
          this.usersdata = res.data.data;
          if (res.data.data.houseExplorateBase) {
            this.onedata = res.data.data.houseExplorateBase;
            this.usermgdata = res.data.data.houseExplorateBase;
          }
          if (res.data.data.houseExplorateHouse) {
            this.twoimg = res.data.data.houseExplorateHouse;
          }
          if (res.data.data.houseExplorateScheme) {
            this.threedata = res.data.data.houseExplorateScheme;
          }
          if (res.data.data.houseExplorateBw) {
            this.fourdata = res.data.data.houseExplorateBw;
          }
        });

      this.d = d;
      console.log(d, i);
      this.diashow = i;
      this.dialogVisibleimg = true;
    },
    // 获取审核数据
    getshlist() {
      this.$http
        .post("houseExplorateBase/queryOne", this.queryInfo)
        .then(res => {
          this.onedata = res.data.data;
        });
      this.$http
        .post("houseCustomer/queryOne", {
          customerCode: this.coid
        })
        .then(res => {
          this.usersdata = res.data.data;
        });
      this.$http
        .post("houseExplorateHouse/queryOne", this.queryInfo)
        .then(res => {
          this.twodata = res.data.data;
          this.twoimg = res.data.data;
        });
      this.$http
        .post("houseExplorateScheme/queryOne", this.queryInfo)
        .then(res => {
          this.threedata = res.data.data;
        });
      this.$http.post("houseExplorateBw/queryOne", this.queryInfo).then(res => {
        this.fourdata = res.data.data;
      });
      this.$http
        .post("houseExplorateExamineNode/queryNodeDetail", {
          customerCode: this.coid
        })
        .then(res => {
          console.log(res.data.data);
          this.rightdata = res.data.data;
        });
      // 基本信息照片
      this.$http
        .post("houseCustomer/queryImgList", {
          customerCode: this.coid
        })
        .then(res => {
          this.usermgdata = res.data.data;
        });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getdata();
    },
    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getdata();
    },
    hook() {
      this.getdata();
    },
    cz() {
      this.queryInfo.condition = {
        dataStatus: 3,
        mobile: "",
        explorateCode: "",
        customerName: ""
      };
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getdata();
    }, 300000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    this.getdata();
  },
  data() {
    return {
      sceneVideo: '',
      materialList:[],
      isClicked: false,
      timer: "",
      count: 0,
      usersdata: "",
      coid: "",
      codeshow: 0,
      steps: [
        {
          title: "房屋信息",
          index: 0,
          icon: "el-icon-message"
        },
        {
          title: "方案信息",
          index: 1,
          icon: "el-icon-message"
        }
      ],
      activeStep: 1,
      rightdata: [],
      d: "",
      ondelist: {
        processName: "基本信息",
        processResult: 0,
        commentList: [
          {
            comment: "",
            commentNo: "",
            commentImgList: [
              {
                commentImgNo: "",
                commentImgUrl: ""
              }
            ]
          }
        ]
      },
      twdelist: {
        processName: "房屋信息",
        processResult: 0,
        commentList: [
          {
            comment: "",
            commentNo: "",
            commentImgList: [
              {
                commentImgNo: "",
                commentImgUrl: ""
              }
            ]
          }
        ]
      },
      thrdelist: {
        processName: "方案信息",
        processResult: 0,
        commentList: [
          {
            comment: "",
            commentNo: "",
            commentImgList: [
              {
                commentImgNo: "",
                commentImgUrl: ""
              }
            ]
          }
        ]
      },
      fourdelist: {
        processName: "井网信息",
        processResult: 0,
        commentList: [
          {
            comment: "",
            commentNo: "",
            commentImgList: [
              {
                commentImgNo: "",
                commentImgUrl: ""
              }
            ]
          }
        ]
      },
      bzde: {
        customerCode: "",
        customerName: "",
        techStatus: "",
        techcreateCode: "",
        techcreateName: "",
        explorateCode: "",
        processList: [
          {
            processName: "",
            processResult: 0,
            commentList: [
              {
                comment: "",
                commentNo: "",
                commentImgList: [
                  {
                    commentImgNo: "",
                    commentImgUrl: ""
                  }
                ]
              }
            ]
          }
        ]
      },
      usermgdata: {},
      isShow: false,
      imgll: [],
      imglll: [],

      diashow: "",
      twoimg: {},
      onedata: {},
      twodata: {},
      threedata: {},
      fourdata: {},
      imgonelist: {
        one: [
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
            flag: false
          },
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
            flag: false
          }
        ],
        two: [
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"
          },
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"
          }
        ],
        three: [
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"
          },
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"
          }
        ]
      },
      imgonelist: {
        one: [
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
            flag: false
          },
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
            flag: false
          }
        ],
        two: [
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"
          },
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"
          }
        ],
        three: [
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"
          },
          {
            url:
              "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"
          }
        ]
      },
      queryInfo: {
        pageSize: 20,
        pageCount: 1,
        currPage: 1,
        condition: {
          // dataStatus:3,
          // mobile: "",
          // explorateCode:'',
          // customerName:'',
        }
      },
      dialogVisible: false,
      dialogVisibleimg: false,
      activities: [
        {
          time: "1998-10-09",
          one: "通过",
          two: "通过",
          three: "通过",
          four: "不通过",
          content: {
            title: "",
            tex: {
              name: "",
              img: []
            },
            texs: {
              name: "",
              img: []
            }
          }
        },
        {
          time: "1998-10-09",
          one: "通过",
          two: "通过",
          three: "通过",
          four: "不通过",
          content: {
            title: "审批意见1",
            tex: {
              name: "1.资料补全",
              img: []
            },
            texs: {
              name: "2.资料补全",
              img: [
                "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"
              ]
            }
          }
        }
      ],
      url:
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
      srcList1: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg"
      ],
      srcList2: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg"
      ],
      srcList3: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg"
      ],
      srcList4: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg"
      ],
      srcList5: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg"
      ],
      tips: [
        {
          url: [],
          tip: ""
        }
      ],
      radio: 3,
      active: 0,
      drawer: false,
      tableData: [],
      formInline: {
        user: "",
        mobile: "",
        explorateCode: "",
        customerName: ""
      },
      roofType: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      inverterSN: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      installType: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      explorationStatus: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      installationStatus: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      results: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      securityRejections: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      cooperationType: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      explorationAnomalies: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      explorationArea: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      firm: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      dataType: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      securityDocument: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      roofNum: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      wellPattern: [
        {
          region: "选项1",
          label: "黄金糕"
        },
        {
          region: "选项2",
          label: "双皮奶"
        },
        {
          region: "选项3",
          label: "蚵仔煎"
        },
        {
          region: "选项4",
          label: "龙须面"
        },
        {
          region: "选项5",
          label: "北京烤鸭"
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      value1: [],
      value2: ""
    };
  }
};
</script>

<style lang="less" scoped>
.expoleManageBox {
  font-size: 14px;
  margin: 0;
  padding: 10px;
  margin-right: 10px;

  .el-date-editor .el-range__icon {
    line-height: 21px !important;
  }

  .top {
    // margin-bottom: 10px;
  }
  .form {
    background-color: #fff;
    padding-top: 20px;
    margin-bottom: 10px;
    .el-form-item .el-input {
      // width: 215px;
    }
  }
  .pagination {
    text-align: right;
    // float: right;
  }
  .bgwhite::-webkit-scrollbar {
    display: none;
    margin-top: 10px;
    padding: 20px;
    overflow: auto;
    scrollbar-width: none; /* 隐藏滚动条宽度（仅适用于Firefox） */
    -ms-overflow-style: none; /* 隐藏滚动条（仅适用于IE和Edge） */
    background-color: white;
  }
  .el-button {
    // padding: 0 10px;
  }
}
.spnacitive {
  color: red !important;
}
.drw {
  display: flex;
  height: 100vh;
}
.drw-left {
  padding-top: 15px;
  width: 70%;
  border-right: 1px solid #c1c1c1;
  .drw-user {
    .jj {
      display: flex;
      div {
        flex: 1;
        margin: 10px;
        margin-left: 15px;
      }
    }
  }
  .drw-title {
    font-size: 16px;
    border-bottom: 3px solid #02a7f0;
    width: 80px;
    margin-left: 10px;
    text-align: center;
  }
  .box {
    border: 14px solid #f2f2f2;
    .box-de {
      padding: 0 40px;
      // height: 600px;
      height: 60vh;
      overflow: auto;
      padding-bottom: 50px;
      scrollbar-width: none; /* 隐藏滚动条宽度（仅适用于Firefox） */
      -ms-overflow-style: none; /* 隐藏滚动条（仅适用于IE和Edge） */
      .de-one {
        .de-de {
          display: flex;
          margin: 5px;
          margin-bottom: 10px;
          div {
            flex: 1;
            font-size: 14px;
            white-space: nowrap; /* 让文本不换行 */
            overflow: hidden; /* 隐藏溢出部分 */
            text-overflow: ellipsis; /* 显示省略号 */
          }
        }
      }
      .de-img {
        .img-tit {
          font-weight: 700;
        }
        .imgde {
          margin-left: 20px;
          margin-top: 10px;
          font-size: 14px;
          .imgli {
            display: flex;

            img {
              width: 114px;
              height: 75px;
              border-radius: 5px;
              margin-right: 40px;
            }
          }
        }
      }
      .de-bom {
        border-top: 6px solid #e2e2e2;
        .bom-1 {
          display: flex;
          margin: 15px;
          align-items: center;
        }
        .tips {
          // width: 744px;
          // height: 252px;
          padding: 2px 2px 2px 2px;
          border-radius: 5px;
          border: 1px solid rgba(215, 215, 215, 0.45);
          margin-left: 15px;
          padding: 20px;
          position: relative;
          .xz {
            margin: 15px;
          }
          .yj {
            margin: 15px;
          }
          .colse {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .box-3 {
      padding: 0 40px;
      height: 55vh;
      overflow: auto;
      padding-bottom: 50px;
      .sj {
        width: 100%;
        padding: 2px 2px 2px 2px;
        border-radius: 5px;
        border: 1px solid rgba(129, 211, 248, 0.22);
        background-color: rgba(129, 211, 248, 0.22);
        height: 52px;
        line-height: 52px;
        font-weight: 700;
        padding-left: 20px;
        box-sizing: border-box;
      }
      .sj-tit {
        font-weight: 700;
        padding-left: 20px;
        border-bottom: 1px solid #333;
        height: 52px;
        line-height: 52px;
      }
      .sj-wj {
        margin-top: 30px;
        margin-left: 20px;
        display: flex;
        .sjname {
          color: #333;
        }
        .wj {
          margin: 20px;
          display: flex;
          // flex-wrap: wrap;
          .tii {
            text-align: center;
          }
        }
      }
      .sj-deta {
        .da-li {
          display: flex;
          padding: 5px 30px;
          box-sizing: border-box;
          font-size: 14px;
          div {
            flex: 1;
            color: #555555;
          }
        }
      }
      .tip-box {
        color: #333333;
        font-size: 13px;
        margin-top: 20px;
      }
      .xc-img {
        display: flex;
        flex-wrap: wrap;
        .xclist {
          width: 139px;
          margin-left: 60px;
          margin-top: 10px;
          div {
            height: 40px;
            line-height: 40px;
          }
        }
      }
      .xc-video {
        margin: 40px;
        .xc-li {
          color: #555555;
          font-size: 15px;
          div {
            line-height: 40px;
          }
        }
      }
      .de-bom {
        border-top: 6px solid #e2e2e2;
        .bom-1 {
          display: flex;
          margin: 15px;
          align-items: center;
        }
        .tips {
          // width: 744px;
          // height: 252px;
          position: relative;
          padding: 2px 2px 2px 2px;
          border-radius: 5px;
          border: 1px solid rgba(215, 215, 215, 0.45);
          margin-left: 15px;
          padding: 20px;
          margin-bottom: 10px;
          .xz {
            margin: 15px;
          }
          .yj {
            margin: 15px;
          }
          .colse {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.drw-right {
  padding-top: 15px;
  width: 30%;
  padding-left: 20px;
  .bo {
    margin-left: 60%;
    width: 100px;
    height: 40px;
  }
  .right-tit {
    border-bottom: 1px solid rgba(215, 215, 215, 0.55);
    line-height: 40px;
  }
  .timex {
    color: #333;
    .shjl {
      color: #333;
    }
  }
}
.jg {
  padding-left: 40px;
  height: 500px;
  overflow: auto;
  .jg-o {
    display: flex;
    align-items: center;

    .jg1 {
      color: #333333;
      font-size: 20px;
    }
    .jg2 {
      width: 105px;
      height: 33px;
      padding: 2px 2px 2px 2px;
      border-radius: 5px;
      border: 1px solid #02a7f0;
      background-color: #ffffff;
      color: #02a7f0;
      text-align: center;
      line-height: 33px;
    }
  }
  .jg-li {
    .shjl {
      div {
        margin-bottom: 15px;
      }
      img {
        width: 100px;
        height: 100px;
      }
      .shmag {
        margin-left: 170px;
      }
    }
  }
}
.jgdrg {
  .imglist {
    margin-left: 40px;
    .list {
      display: flex;
      img {
        width: 151px;
        height: 91px;
        border-radius: 5px;
        margin-right: 20px;
      }
      .listactive {
        border: 3px solid skyblue;
      }
    }
  }
}
.listactive {
  border: 3px solid skyblue;
}
.tips {
  margin-bottom: 15px;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}
/deep/ .el-table td {
  padding: 4px 0;
}
/deep/ .el-form-item__label {
  font-size: 11px;
}
/deep/ .el-step__icon.is-text {
  background-color: #02a7f0;
  color: #fff;
  border: 2px solid #02a7f0;
}
/deep/ .el-drawer__header > :first-child {
  color: #000;
  font-weight: 700;
  font-size: 18px;
}
.xc-tit {
  color: #555555;
}
.one-name {
  color: #555555;
}
/deep/ .el-drawer__header {
  margin-bottom: 0px;
  border-bottom: 1px solid #c1c1c1;
  padding: 20px;
}
/deep/ .el-steps--vertical {
  height: 0px;
}

.bran {
  display: flex;
  align-items: center;
  margin-top: 10px;
  div {
    text-align: end;
    margin-right: 10px;
  }
}
</style>
